import { Override } from "../types";
import {
  AssistType,
  DailyHabit as HabitDto,
  Event as EventDto,
  PlannerActionIntermediateResult,
  SubscriptionType,
  Task as TaskDto,
  TaskOrHabit as TaskOrHabitDto,
} from "./client";
import { dtoToEvent, Event } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToTask, Task } from "./Tasks";
import { NotificationKeyStatus, TransformDomain } from "./types";

export type TaskOrHabit = Override<
TaskOrHabitDto,
  {
    title: string | null;
    location?: string | null;
  }
>

export const isTask = (taskOrHabit: TaskOrHabit): taskOrHabit is TaskDto => taskOrHabit.type === AssistType.TASK;
export const isHabit = (taskOrHabit: TaskOrHabit): taskOrHabit is HabitDto => !!taskOrHabit.type && taskOrHabit.type !== AssistType.TASK;

export type PlannerActionResult = {
  events: Event[];
  task?: Task;
  habit?: Habit;
};

export const fromDto = (dto: PlannerActionIntermediateResult): PlannerActionResult => {
  const result: PlannerActionResult = {
    events: dto.events.map((event: EventDto) => dtoToEvent(event)),
  };

  if (isTask(dto.taskOrHabit as TaskOrHabit)) {
    result.task = dtoToTask(dto.taskOrHabit as TaskDto);
  } else {
    result.habit = dtoToHabit(dto.taskOrHabit as HabitDto);
  }

  return result;
};

export class PlannerDomain extends TransformDomain<PlannerActionResult> {
  resource = "Planner";
  cacheKey = "planner";

  public deserialize = fromDto;

  handleIntermediateResult = (result: PlannerActionIntermediateResult): PlannerActionIntermediateResult => {
    const subType = result.taskOrHabit.type === AssistType.TASK ? SubscriptionType.Task : SubscriptionType.DailyHabit;

    // Find active subscriptions and pipe data through.
    this.ws.subscriptions.forEach((value, key) => {
      if (key.indexOf(`${subType}_`) >= 0) {
        this.ws.pushMessage(result.taskOrHabit, subType, key);
      } else if (key.indexOf(`${SubscriptionType.Events}_`) >= 0) {
        this.ws.pushMessage(result.events, SubscriptionType.Events, key);
      }
    });

    return result;
  };

  /**
   * Task Actions
   */
  markTaskDone = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      return this.api.planner
        .taskDone(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not mark task as done");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  extendTask = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      return this.api.planner
        .extendTask(id, { minutes, notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not extend task");
          throw reason;
        });
    })
  );

  logWork = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      return this.api.planner
        .logWork(id, { minutes, notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not log work for task");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  restartTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      return this.api.planner
        .restartTask(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not restart task");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  snoozeTask = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      // TODO (IW): Remove default once not required on backend
      return this.api.planner
        .snoozeTask1(id, minutes || 240, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not restart task");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  startTaskNow = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);

      return this.api.planner
        .startTaskNow(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not start task now");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  stopTaskNow = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.generateUid("planner", id);
      this.expectChange(notificationKey, id, {}, true);
      
      return this.api.planner
        .stopTask(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.handleIntermediateResult(response);
        })
        .catch((reason) => {
          console.warn("Request failed: Could not start task now");
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );
}
